import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "@hotwired/turbo-rails"

if (window.location.href.includes('/admin') ) {
    window.Turbo = Turbo
  } else {
    Turbolinks.start()
  }

Rails.start()
ActiveStorage.start()

require("trix")
require("@rails/actiontext")
require("channels")

import '@cable_ready/polyfills'
import "controllers"
import 'bootstrap'
import './trix-editor-overrides'