import { Controller } from "stimulus"
export default class extends Controller {

    checkEmail(email){
        let validEmail = true
        let validFormat = this.getElement('required-email-valid')
        if (!this.emailIsValid(this.getElement(email).value)) {
        this.getElement(email).focus()
        validFormat.classList.remove("d-none")
        validEmail = false
        } else {
        validFormat.classList.add("d-none")
        }
        return validEmail
    }
    
    emailIsValid(email) {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
    }

    async checkRtn(url = '', data = {}) {    
        const response = await fetch(url, {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: 'same-origin', // include, *same-origin, omit
        })
        return response.json() // parses JSON response into native JavaScript objects
    }

    clickRegistration(e) {
        const registration = e.target.value
        let registration_id = document.getElementById('registrationId')
        let support_registration_id = document.getElementById('support_registration_id')
        if (registration == '1'){
            registration_id.classList.remove('d-none')
            support_registration_id.setAttribute('required', 'required');
            support_registration_id.setAttribute('aria-required', true);
        } else {
            registration_id.classList.add('d-none')
            support_registration_id.removeAttribute('required');
            support_registration_id.removeAttribute('aria-required');
            support_registration_id.value = null
        }
    }

    selectRtn(e){

        const rtn = e.target.value
        const url = `/clients/supports/selected_rtn?registration_id=${rtn}`

        this.checkRtn(url, {rtn: rtn}).then(data => {
            document.getElementById('support_is_commercial').value = data.commercial
            document.getElementById('support_account_name').value = data.account_name
        })
    }
    submitForm(e) {
        let hcatcha = document.getElementsByClassName('h-captcha')[0]
        let opp_id2 = document.getElementsByClassName('opp_id2')[0]
        if(typeof opp_id2 !== "undefined"){
            if (typeof hcatcha !== "undefined") {
                let hcatcha_response = hcatcha.querySelector("iframe").getAttribute("data-hcaptcha-response")
                if (hcatcha_response == "Invalid Data" || hcatcha_response == "") {
                    alert("Please complete the captcha")
                    e.preventDefault()
                }else{
                    document.getElementById('htags').value = hcatcha_response
                }
            }else{
                alert("Please complete the captcha")
                e.preventDefault()
            }
        }
    }

    changeEmail(e) {
        let url = '/verify_email'
        let email = e.target.value
        if (email != '') {
          if (this.emailIsValid(email)) {

              this.hideElement("email_check")
              this.hideElement("email_x")
              this.hideElement("email_question")
              this.showElement("email_load")
              this.showElement("email_load_text")
              this.hideElement("required-email")
              this.hideElement("required-email-valid")
              const params = {email: email}
              this.getEmail(url, params) 
                .then(data => {
                  this.verifyEmail(data)
                });

          }
        }
      }

    verifyEmail(data) {
        // let summaryEmail = this.getElement("summary-email")
        // let email = this.getElement("email")
        
        if (data[0].deliverable == true) {
            this.hideElement("email_load")
            this.hideElement("email_load_text")
            this.showElement("email_check")
            this.getElement("send_btn").disabled = false
            this.getElement("opp_id3").value = Date.now()
            // this.getSelected("email_status", "good")
        } else if (data[0].deliverable == false) {
            this.hideElement("email_load")
            this.hideElement("email_load_text")
            this.showElement("email_x")
            this.getElement("send_btn").disabled = true
            // this.getSelected("email_status", "bad")
            alert("Can you please check the spelling of your email address? The email checker was not able to verify your email address. This could mean that we cannot reach you. Please press the blue Ok button on the screen below to return to the page to enter your email address.")
            // summaryEmail.innerHTML = email.value + ' <i class="text-danger fas fa-times-circle"></i>'
        } else {
            this.hideElement("email_load")
            this.hideElement("email_load_text")
            this.showElement("email_question")
            // this.getSelected("email_status", "unknown")
            alert("The email checker was not able to check if you entered a valid email address. Please double check the spelling of your email address. If your email address is spelled incorrectly we cannot reach you. Please press the orange Back button on the screen below to return to the page ‘Your details’ to check the spelling of your email address")
        }
    }

    async getEmail(url = '', data = {}) {    
    const response = await fetch(url, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        headers: {
        'Content-Type': 'application/json'
        },
        credentials: 'same-origin', // include, *same-origin, omit
        body: JSON.stringify(data) // body data type must match "Content-Type" header
    })
    return response.json() // parses JSON response into native JavaScript objects
    }

    getElement(id){
        return document.getElementById(id)
    }

    showElement(id) {
        document.getElementById(id).classList.remove('d-none')
    }

    hideElement(id) {
        document.getElementById(id).classList.add('d-none')
    }

}