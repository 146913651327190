import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ "id", "clearBtn", "useEmailCode" ]

  append_clear(event){
    let clear_btn = document.getElementById(this.clearBtnTarget.id);
    (event.currentTarget.value == "") ? clear_btn.style.display = "none" : clear_btn.style.display = "block"
  }

  input_clear(){
    this.idTarget.value = ''
  }
  
  back_button(){
    window.history.back()
  }

  submit(event) {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      this.element.requestSubmit()
    }, 500)
  }
}