import CheckNumberController from './check_number_controller'

export default class extends CheckNumberController {

  selectCountryCodeOtp(e){
    const phone_number = this.getElement('phone').value
    if (phone_number !== "") {
      this.checkValidNumberOtp()
    }
    if (e.currentTarget.value !== "") {
      this.hideElement('required-country')
    }
  }

  checkValidNumberOtp(){
    const country_code = this.getElement('country_code').value  
    const phone_number = this.getElement('phone').value
    document.getElementById('send_otp').disabled =  false
    if ( country_code === "" || phone_number === "") {
      this.checkInputOtp(country_code, phone_number)
    }
  }

  checkInputOtp(country_code){
    country_code === "" ?  this.showHideInputValidation('required-country', 'required-number', 'send_otp')
      : this.showHideInputValidation('required-number', 'required-country', 'send_otp')
  }
}