import { Controller } from "stimulus"

export default class extends Controller {

  selectedUser(e){
    let email_value = document.getElementById('user_email')
    email_value.value = e.currentTarget.value
  }

  checkAllProduct(event){
    const checkbox = event.currentTarget.checked
    const checkboxes = document.querySelectorAll('input[class="product_list"]');
    checkbox ? this.insertAllValue(checkboxes) : this.removeAllvalue(checkboxes)
  }

  checkedProducts(){
    let checkedCount = 0
    let checkboxes = document.querySelectorAll('input[class="product_list"]');
    checkboxes.forEach(function(checkbox) {
      if (checkbox.checked) {
        checkedCount++
      }
    })
 
    return checkedCount
  }

  insertAllValue(checkboxes){
    for (var checkbox of checkboxes) {
      checkbox.checked = true;
      this.insertValue(checkbox.value, '')
        
    }
  }
  removeAllvalue(checkboxes){
    for (var checkbox of checkboxes) {
      checkbox.checked = false;
      this.removeValue(checkbox.value)
    }
  }

  checkProduct(event){
    const checkboxes = document.querySelectorAll('input[class="product_list"]');
    const checkbox = event.currentTarget.checked
    const value = event.currentTarget.value
    checkbox ? this.insertValue(value, checkboxes) : this.removeValue(value)
  }

  insertValue(value, checkboxes){
    const checkedCount = this.checkedProducts()
    if (checkboxes != '' && checkboxes.length == checkedCount) {
      document.getElementById('checkAllProducts').checked = true
    }
   
    const id = 'user_product_ids'
    let get_array = document.getElementById(id)
    get_array.value == "" ? get_array.value = value 
        : get_array.value = get_array.value + ' ' + value
  }

  removeValue(value){
    const id = 'user_product_ids'
    document.getElementById('checkAllProducts').checked = false
    let checkedArray = this.getArray(id)
    const remove_array = checkedArray.filter(v => v !== value)
    this.newArray(id, remove_array)
  }

  newArray(id, array_value){
    return document.getElementById(id).value = array_value.join(' ')
  }

  getArray(id){
		return document.getElementById(id).value.split(' ')
  }
  
 
}