import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    // Prevent context menu (right-click)
    this.element.addEventListener("contextmenu", this.disableContextMenu);
    // Prevent specific keyboard shortcuts for inspect element (F12, Ctrl+Shift+I)
    document.addEventListener("keydown", this.disableInspectElement);
  }

  disconnect() {
    this.element.removeEventListener("contextmenu", this.disableContextMenu);
    document.removeEventListener("keydown", this.disableInspectElement);
  }

  disableContextMenu(event) {
    event.preventDefault();
  }

  disableInspectElement(event) {
    if (event.keyCode === 123 || (event.ctrlKey && event.shiftKey && event.keyCode === 73)) {
      event.preventDefault();
    }
  }
}