import { Controller } from "stimulus"

export default class extends Controller {
  connect(){
    this.checkValidNumber()
  }
  
  selectCountryCode(e){
    const phone_number = this.getElement('user_phone').value
    if (phone_number !== "") {
      this.checkValidNumber()
    }
    if (e.currentTarget.value !== "") {
      this.hideElement('required-country')
    }
  }

  checkValidNumber(){
    if(this.getElement('user_use_email_code').checked == false){
      const country_code = this.getElement('user_country_code').value  
      const phone_number = this.getElement('user_phone').value

      const url = '/clients/valid_contacts'
      const params = {country_code: country_code, phone_number: phone_number}

      country_code !== "" && phone_number !== "" ? this.getVerifyInputNumber(url, params)
        : this.checkInputs(country_code, phone_number)
    }
  }

  getVerifyInputNumber(url, params){
    let check_number = document.getElementById('check_number_text')
    let check_number_load = document.getElementById('check_number_load')
    this.showElement('check_number_text')
    this.showElement('check_number_load')

    this.hideElement('required-number')
    this.hideElement('required-country')
    check_number.classList.add('text-danger')
    check_number.classList.remove('text-success')
    check_number.innerHTML = '<small>Verifying phone number</small>'
    check_number_load.innerHTML = '<i class="fas fa-spinner fa-pulse mr-2 text-primary"></i>'
    this.checkNumber(url, params).then(
      data => {this.verifyNumber(data)})
  }

  checkInputs(country_code, phone_number){
    if (country_code === "" && phone_number === "") {
      this.showElement('required-number')
      this.showElement('required-country')
    } else {
      this.checkInput(country_code)
    }
  }

  verifyNumber(data){
    // console.log(data)
    data ? this.validNumber() : this.invalidNumber()
  }

  validNumber(){
    this.modifyVerifyClasslist('check_number_text','check_number_load', '',
    'text-danger', 'text-success')
    let check_load =  document.getElementById('check_number_load')
    check_load.innerHTML = '<label class="text-success">Verified</label>'+ '<svg xmlns="http://www.w3.org/2000/svg"  height="20" weight="30" class="text-success" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" /></svg>' 
    document.getElementById('btn-update').disabled =  false
  }

  invalidNumber(){
    this.modifyVerifyClasslist('check_number_text','check_number_load', 'Invalid Mobile Phone Number',
    'text-success','text-danger')
    let check_load =  document.getElementById('check_number_load')
    check_load.innerHTML = '<i class="text-danger fas fa-times-circle"></i>' 
    document.getElementById('btn-update').disabled =  true
  }
  
  modifyVerifyClasslist(number_text, number_load, message, remove_class, add_class){
    let check_number = document.getElementById(number_text)
    let check_class = check_number.classList.contains(remove_class)
    if (check_class) {
      check_number.classList.remove(remove_class)
      check_number.classList.add(add_class)
    }
    check_number.innerHTML = `<small>${message}</small>`
    
  }

  checkInput(country_code){
    country_code === "" ?  this.showHideInputValidation('required-country', 'required-number', 'btn-update')
      : this.showHideInputValidation('required-number', 'required-country', 'btn-update')
  }

  showHideInputValidation(input_one, input_two, btn){
    this.showElement(input_one)
    this.hideElement(input_two)
    document.getElementById(btn).disabled =  true
    
  }

  async checkNumber(url = '', data = {}) {    
    const response = await fetch(url, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'same-origin', // include, *same-origin, omit
      body: JSON.stringify(data) // body data type must match "Content-Type" header
    })
    return response.json() // parses JSON response into native JavaScript objects
  }

  getElement(id){
    return document.getElementById(id)
  }

  showElement(id) {
    document.getElementById(id).classList.remove('d-none')
  }

  hideElement(id) {
    document.getElementById(id).classList.add('d-none')
  }
}