import consumer from "./consumer"

consumer.subscriptions.create("BellChannel", {
  connected() {
    // this.send({ message: 'Client is live' })
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    this.notification_count_outer(data)
    this.appendNotif(data)
    this.notification_count_inner(data)
  },

  appendNotif(data) {
    const notif_id = 'notifications-'+ data['user']
    const html = this.createNotif(data)
    const element = document.getElementById(notif_id)
    if (element) {
      element.insertAdjacentHTML("afterbegin", html)
    }
    
  },

  createNotif(data){
   if (data['check_notif'] == true) {
    return this.createNotifComments(data)
   } else {
    return this.createNotifStatus(data)
   }
  },

  createNotifStatus(data){
    return `<a href='/clients/opportunities/${data['opportunity']['id']}' id='link-${data['notification']['id']}'
    class='list-group-item list-group-item-action border-bottom border-light' data-action='click->navbar#read_notification'>
    <div class='row align-items-center'>
      <div class='col ps-0 ms-2'>
        <div class='d-flex justify-content-between align-items-center'>
            <div>
              <small class='mb-0 text-primary'>Request registration ${data['opportunity']['request_tracking_number']}
              </small><small class='mb-0 text-small'>${data['notification']['message']}</small>
            </div>
            <div class='text-end'>
              <small class='text-danger'>${data['created_at']} ago</small>
            </div></div></div></div></a>`
  },

  createNotifComments(data){
    return `<a href='/clients/comments?opportunity_id=${data['opportunity']['id']}&user_access_id=${data['user_access']}' id='link-${data['notification']['id']}'
    class='list-group-item list-group-item-action border-bottom border-light' data-action='click->navbar#read_notification'>
    <div class='row align-items-center'>
      <div class='col ps-0 ms-2'>
        <div class='d-flex justify-content-between align-items-center'>
            <div>
              <small class='mb-0 text-primary'>${data['opportunity']['request_tracking_number']}
              </small><small class='mb-0 text-small'>${data['notification']['message']}</small>
            </div>
            <div class='text-end'>
              <small class='text-danger'>${data['created_at']} ago</small>
            </div></div></div></div></a>`
  },

  notification_count_outer(data){
    const notif_id = 'notifications-count-'+ data['user']
    const element = document.getElementById(notif_id)
    if (element) {
      element.classList.remove('d-lg-none')
      element.classList.add('icon-badgeV2', 'rounded-circle', 'unread-notifications')
      element.innerHTML = data['count']
    }
  
  },

  notification_count_inner(data){
    const notif_id = 'notifications-count-inner-'+ data['user']
    const element = document.getElementById(notif_id)
    if (element) {
      element.innerHTML = data['count_plural']
    }
  }

});
