import { Controller } from "stimulus"

export default class extends Controller {
  chosen_rate(e){
    const forlogout = e.currentTarget.dataset.forlogout;
    if(forlogout == 1 || forlogout == "1"){
      const div_r = document.getElementById('survey_login');
      div_r.querySelectorAll("div#chosen_rate")[0].innerHTML = (e.currentTarget.value != 'Neutral'? `<label>Please describe why you are ${e.currentTarget.value}</label>` : `<label>Please describe why you are Neutral<label>`)
    }else{
      const div_r = document.getElementById('survey');
      div_r.querySelectorAll("div#chosen_rate")[0].innerHTML = (e.currentTarget.value != 'Neutral'? `<label>Please describe why you are ${e.currentTarget.value}</label>` : `<label>Please describe why you are Neutral<label>`)
    }
  }

  click_survey(e){
    const opportunity =  e.currentTarget.id
    const support = e.currentTarget.id
    const surveyform = document.getElementById('survey');
    if(surveyform.querySelectorAll('input#opportunity').length > 0){
      surveyform.querySelectorAll('input#opportunity')[0].value = opportunity
    }else{
      surveyform.querySelectorAll('input#support_id')[0].value = support
    }
  }

  click_survey_logout(e){
    e.stopImmediatePropagation()
    const opportunity =  e.currentTarget.id
    document.getElementById('survey_login').querySelectorAll('input#opportunity')[0].value = opportunity
  }
}