import { Controller } from 'stimulus'

export default class extends Controller {

  read_notification(e){
    e.preventDefault();
    const redirectUrl = e.currentTarget.href
    const notification_id = e.currentTarget.id.replace('link-','')
    const url = '/api/v1/read_notification'
    const params = {notification_id: notification_id}
    this.getUrl(url, params)
    .catch(error=>{console.log(error)})
    .then(()=>{window.location.href=redirectUrl})
  }

  async getUrl(url = '', data = {}) {    
    const response = await fetch(url, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'same-origin', // include, *same-origin, omit
      body: JSON.stringify(data) // body data type must match "Content-Type" header
    })
    return response.json() // parses JSON response into native JavaScript objects
  }
}