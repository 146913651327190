import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "timer", "resend", "resendDiv", "inputOtp" ]

  connect() {
    let timer_div = document.getElementById('otp-timer-counter')
    let timer = timer_div.getAttribute('data-value')
    document.getElementById('resend_btn').disabled = true
    if(timer > 0) {
      document.getElementById('otp-timer-counter-id').classList.remove("d-none");
      let interval = setInterval(function () {
        timer--;
        let mins = Math.floor(parseInt(timer) / 60)
        let secs = (parseInt(timer) % 60)
        secs = secs < 10 ? "0"+secs : secs
        timer_div.innerHTML = "0"+mins+":"+secs
        if(timer == 0){
          document.getElementById('resend_btn').disabled = false
          document.getElementById('otp-timer-counter-id').classList.add("d-none");
          clearInterval(interval); 
        }
      }, 1000);
    }else{
      document.getElementById('resend_btn').disabled = false
    }
  }

  getMinutes(time){
    return Math.floor(parseInt(time) / 60);
  }

  getSeconds(time){
    return parseInt(time) % 60;
  }
  
  initResendOtp(event){
    // const status = this.getElement('status').value
    event.target.disabled = true
    const url = '/users/resend_otp'
    this.getUrl(url).then(
      data => {this.verifyResendOtp(data)})
  }

  verifyResendOtp(data){
    data ? this.checkOtpStatus()
      : this.invalidResendOtp()
  }

  checkOtpStatus(){
    let check_load =  this.getElement('invalid_resend')
    check_load.classList.add('d-none')
    Turbolinks.visit(location.href)
  }
  
  invalidResendOtp(){
    let check_load =  this.getElement('invalid_resend')
    check_load.classList.remove('d-none')
  }

  getElement(id){
    return document.getElementById(id)
  }

  async getUrl(url = '', data = {}) {    
    const response = await fetch(url, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'same-origin', // include, *same-origin, omit
      body: JSON.stringify(data) // body data type must match "Content-Type" header
    })
    return response.json() // parses JSON response into native JavaScript objects
  }

 
}