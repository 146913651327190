import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    if(document.getElementById("vendorRole") != null || document.getElementById("deleteModalContent") != null){
      document.getElementById("showModalBtn").click();
    }
    if(document.getElementById("vendorRole") != null && document.getElementById("vendorRole").checked == true){
      document.getElementById("submit_btn").innerHTML  = 'Next';
    }
  }
  
  handleRoleChange(event){
    if(event.currentTarget.getAttribute('id') == 'vendorRole'){
      document.getElementById("submit_btn").innerHTML  = 'Next';
    }else{
      document.getElementById("submit_btn").innerHTML  = 'Update';
    }
  }
}